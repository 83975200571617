<template>
  <div class="tags" v-if="showTags">
    <ul>
      <li
        class="tags-li"
        v-for="(item, index) in tagsList"
        :class="{ active: isActive(item.path) }"
        :key="index"
      >
        <!-- :style="{ opacity: showActive(item.path) }" -->
        <!-- <img
          
          style="opacity:1"
          src="/images/sideBarLeft.png"
          alt
          class="tags-img"
          srcset
        />-->
        <router-link :to="item.path" class="tags-li-title">{{item.title}}</router-link>
        <span class="tags-li-icon" @click="closeTags(index)" v-show="showTagClose">
          <i class="el-icon-close"></i>
        </span>
        <!-- <img
          style="opacity:1"
          src="/images/sideBarRight.png"
          alt
          class="tags-img"
          srcset
        />-->
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tagsList: [],
    };
  },
  mounted() {},
  computed: {
    showTags() {
      return this.tagsList.length > 0;
    },
    showTagClose() {
      return this.tagsList.length > 1;
    },
  },
  watch: {
    $route(newValue, oldValue) {
      this.setTags(newValue);
    },
  },
  created() {
    this.setTags(this.$route);
  },
  methods: {
    isActive(fullPath) {
      let path = fullPath.indexOf("?") > -1 ? fullPath.split("?")[0] : fullPath;
      return this.$route.fullPath.indexOf(path) > -1; // 路由带参数
    },
    showActive(fullPath) {
      let path = fullPath.indexOf("?") > -1 ? fullPath.split("?")[0] : fullPath;
      if (this.$route.fullPath.indexOf(path) > -1) {
        return "1";
      } else {
        return "0";
      }
    },
    // 关闭单个标签
    closeTags(index) {
      let delItem = this.tagsList.splice(index, 1)[0];
      const item = this.tagsList[index]
        ? this.tagsList[index]
        : this.tagsList[index - 1];
      console.log(item);
      if (item) {
        delItem.path === this.$route.fullPath && this.$router.push(item.path);
      } else {
        this.$router.push("/");
      }
    },
    // 设置标签
    setTags(route) {
      // 点击标签同时处理按钮权限
      if (this.$store.state.menu.menuList) {
        this.$store.state.menu.menuList.forEach((element) => {
          if (element.subs) {
            element.subs.forEach((item) => {
              if (item.menuName === route.meta.title) {
                var btnMenu = [];
                if (item.subs) {
                  item.subs.forEach((sub) => {
                    btnMenu.push(sub.menuName);
                  });
                } else {
                  btnMenu.push("###");
                }
                this.$store.dispatch("now_Menus", btnMenu.join());
              }
            });
          } else {
            if (element.menuName === route.meta.title) {
              var btnMenu = [];
              if (element.subs) {
                if (element.subs) {
                  element.subs.forEach((item) => {
                    btnMenu.push(item.menuName);
                  });
                } else {
                  btnMenu.push("###");
                }
                this.$store.dispatch("now_Menus", btnMenu.join());
              }
            }
          }
        });
      }
      let path = route.fullPath;
      const include = !route.name; // 是否是一级菜单和二级菜单
      const isExist = this.tagsList.some((item) => {
        return route.fullPath.indexOf(item.path) > -1;
      });
      if (!isExist) {
        if (include) {
          this.tagsList.push({
            title: route.meta.title,
            path: path,
            name: route.name,
          });
        }
      }
      if (this.tagsList.length > 5) {
        this.tagsList.splice(1, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tags {
  @include themify() {
      .tags-li:first-child{
            margin-left: 0;

      }
    .tags-li {
      float: left;
      margin-left: 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      font-size: 14px;
      //overflow: hidden;
      cursor: pointer;
      // height: 39px;
      line-height: 38px;
      background: #f7f8f9;
      border: 1px solid #e0e0e0;
      border-bottom: none;
    }
    .active {
      //border-bottom: 1px solid #fff;

      // @include fontColcor("n7");
      // background: #f7f8f9;
      //background: #fff;
      padding-bottom:1px;
      border: 1px solid #0DBC79;
      border-bottom:2px solid #f7f8f9 ;
      margin-bottom: -2px;
      .tags-li-title{
          color: #0DBC79;
      }
    }
    ul {
      // box-sizing: border-box;
      width: 100%;
      height: 35px;
      //padding-left: 15px;
      display: flex;
      // align-items: center;
      // background: #fff;
      border-bottom: 1px solid #0DBC79;
      //overflow: hidden;
      position: relative;
    }

    .tags-li-icon {
      float: left;
      padding-right: 6px;
    }
    .tags-img {
      float: left;
    }

    .tags-li-title {
      float: left;
      max-width: 200px;
      padding: 0 16px 0 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: themed("n7");
      text-decoration: none;
    }

    .tags-close-box {
      position: absolute;
      right: 0;
      top: 0;
      box-sizing: border-box;
      padding-top: 1px;
      text-align: center;
      width: 110px;
      height: 34px;
      background: #f5f6fa;
      box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
      z-index: 10;
    }
  }
}
</style>
