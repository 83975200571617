
<template>
  <div class="sideBar" :style="isCollapse ? 'width:60px' : 'width:240px'">
    <div class="sideBarTop">
      <i
        class="sideBarTopIcon iconfont"
        :class="isCollapse ? 'iconicon_menu_normal1' : 'iconicon_menu_select'"
      ></i>
      <div v-if="!isCollapse" class="sideBarTitle">
         <img src="/images/logo1.png"  @click="toHomepage" />
      </div>
    </div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-vertical-demo"
      @select="handleSelect"
      :collapse="isCollapse"
      :collapse-transition="false"
      :unique-opened="true"
      background-color="#00274000"
      text-color="rgba(255,255,255,0.8)"
      active-text-color="#1de9b6"
      style="padding:0 25px"
    >
      <template v-for="(item,index) in menuList">
        <el-submenu
          :index="item.path"
          :key="index"
          v-if="item.children.length>0 && item.children[0].type === 0"
          background-color="#00274000"
        >
          <template slot="title">
            <i :class="iconUrls[index]" />
            <span slot="title">{{ item.name }}</span>
          </template>
          <el-menu-item
            v-for="(sub,index) in item.children"
            :index="sub.path"
            :key="index"
          >{{ sub.name }}</el-menu-item>
        </el-submenu>
        <el-menu-item v-else :index="item.path" :key="index">
          <i :class="iconUrls[index]" />
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isCollapse: false,
      oDiv: null,
      menuList: [],
      activeIndex: "dashboard",
      iconUrls: ['el-icon-user-solid', "el-icon-s-custom", "el-icon-menu", "el-icon-s-shop", "el-icon-s-tools", "el-icon-s-goods", "el-icon-finished", "el-icon-printer"]
    };
  },
  mounted () {
    this.activeIndex = window.localStorage.getItem("routeName");
    this.menuList = this.$store.state.menu.menuList;
    this.oDiv = document.getElementById("el-main");
  },
  watch: {
    $route: function (val) {
        console.log(this.activeIndex)
      this.activeIndex = val.path.split("/")[1];
    }
		/* oDiv: function(val) {
			var self = this;
			val.addEventListener("click", function() {
				if (!self.isCollapse) {
					self.isCollapse = !self.isCollapse;
				}
			});
		} */
  },

  methods: {
    changeCollapse () {
      this.isCollapse = !this.isCollapse;
    },
    handleSelect (key, keyPath) {
      this.$router.push({ path: `${key}` });
       this.activeIndex = key.split("/")[1];
       console.log(this.activeIndex);
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath);
    },
    toHomepage(){
        this.$router.push("/homepage");
    }
  }
};
</script>

<style lang="scss" scoped>
.sideBar {
  @include themify() {
    height: 100%;
    position: absolute;
    background: linear-gradient(-65deg, #002740, #013f5e, #013f5e);
    z-index: 1000;
    // .el-menu {
    //   border: none;
    // }
    .el-menu--collapse {
      width: 60px;
    }
    .sideBarTop {
      display: flex;
      height: 100px;
      padding: 27px 0;
      align-items: center;
      background: none;
      .sideBarTopIcon {
        margin-left: 17px;
        @include fontColcor("b7");
      }
      .sideBarTitle {
        @include fontColcor("b7");

        font-size: 16px;
        line-height: 14px;
        img {
          width: 91%;
          cursor: pointer;
        }
      }
    }
    .iconfont {
      font-size: 24px;
      margin-left: -4px;
    }
    // .el-submenu .el-menu-item {
    //   margin-left: 16px;
    //   min-width: 180px;
    // }
    // .el-menu-vertical-demo span {
    //   margin-left: 5px;
    //   font-size: 16px;
    // }
    // .el-menu-item:hover,
    // .el-menu-item.is-active {
    //   color: #1de9b6 !important;
    //   border-radius: 20px;
    //   background-color: rgba(29, 233, 182, 0.1) !important;
    // }
    // .el-menu-item i,
    // .el-submenu__title i {
    //   color: rgba(255, 255, 255, 0.8);
    // }
    // .el-menu-item:hover,
    // .el-menu-item:hover i,
    // .el-submenu__title:hover i,
    // .el-menu-item.is-active i {
    //   color: #1de9b6 !important;
    // }
    // .el-submenu.is-active >>> .el-submenu__title i,
    // .el-submenu.is-active >>> .el-submenu__title {
    //   color: #1de9b6 !important;
    // }
  }
}
</style>
<style lang="scss" >
.el-submenu__title{
margin-left:17px;
}
</style>
