<template>
	<div class="header">
		<div class="logo">
			<h3 class="title">
				<!-- <i></i>
				<span>三一五溯源</span>-->
			</h3>
		</div>
		<div class="header-center">
			<!-- <my-tags /> -->
		</div>
		<div class="header-right">
			<div class="header-user-con">
				<!-- 用户名下拉菜单 -->
				<el-dropdown style="width:12vw">
					<!-- 用户头像 -->
					<p class="user-avator">
						<!-- <img src="/images/header.png" alt class="header_Img" srcset /> -->
						<img 
                            v-if="getAvatar(this.avatar)"
							:src="getAvatar(this.avatar)"
							alt
							class="header_img"
							srcset
						/>
                        <img 
                            v-else
							:src="$store.state.user.gender===0?'/images/headgirl.png':'/images/headboy.png'"
							alt
							class="header_img"
							srcset
						/>
						{{ username }}
						<i class="el-icon-more el-icon--right"></i>
					</p>
					<el-dropdown-menu slot="dropdown" trigger="click">
						<!-- <el-dropdown-item @click.native="onDarkTheme">
              <i class="el-icon-s-help"></i>
              暗黑风格
            </el-dropdown-item>
            <el-dropdown-item @click.native="onLightTheme">
              <i class="el-icon-s-help"></i>
              亮色风格
						</el-dropdown-item>-->
						<el-dropdown-item @click.native="toUpdatePersonal">
							<i class="el-icon-unlock"></i>
							修改个人信息
						</el-dropdown-item>
						<el-dropdown-item @click.native="toUpdate">
							<i class="el-icon-unlock"></i>
							修改密码
						</el-dropdown-item>
						<el-dropdown-item @click.native="logOut">
							<i class="el-icon-right"></i>
							退出登录
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
			<div class="form form-search">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="90px"
					class="demo-form form-search"
				>
					<el-form-item label="旧密码：" prop="oldPassword">
						<el-input type="password" v-model.trim="ruleForm.oldPassword" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="新密码：" prop="password">
						<el-input type="password" v-model.trim="ruleForm.password" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="确认密码：" prop="checkPass">
						<el-input type="password" v-model.trim="ruleForm.checkPass" autocomplete="off"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div class="buttonGroup text-right">
				<el-button size="small" type="default" @click="dialogVisible = false">取 消</el-button>
				<el-button size="small" type="primary" @click="confirmUpdatePsw('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="修改个人信息" :visible.sync="dialogVisibleP" width="30%">
			<div>
				<div class="body"></div>
				<personalEdit ref="personalEdit" :userId="userId" @onDialogClose="onDialogClose" />
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="default" size="small" @click="dialogVisibleP = false">取消</el-button>
				<el-button
					type="primary"
					size="small"
					@click="onSave"
				>保存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { loginOut, updatePassword,IMG_PREFIX } from "@/api/lib/api.js";
import { checkPassword } from "@/common/utils/index.js";
import personalEdit from "./components/personalEdit.vue";
import md5 from "js-md5";

export default {
	components: {
		personalEdit,
	},
	created() {
        this.username = localStorage.getItem("username");
        if(this.$store.state.user.avatar){
            this.avatar =this.$store.state.user.avatar;
        }else{
            this.avatar=null;
        }
	},

	data() {
		var validatePass2 = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请再次输入密码"));
			} else if (value !== this.ruleForm.password) {
				callback(new Error("两次输入密码不一致!"));
			} else {
				callback();
			}
		};
		return {
			dialogVisible: false,
			dialogVisibleP: false,
			ruleForm: {
				oldPassword: "",
				newPassword: "",
				checkPass: "",
				user_id: "",
			},
            username:"",
            avatar:null,
			rules: {
				password: [
					{ validator: checkPassword, trigger: "blur" },
					{ required: true, message: "请输入密码", trigger: "blur" },
				],
				oldPassword: [
					{
						required: true,
						message: "请输入旧密码",
						trigger: "change",
					},
				],
				checkPass: [
					{
						required: true,
						message: "请输入确认密码",
						trigger: "blur",
					},
					{ validator: validatePass2, trigger: "blur" },
				],
            },
            userId:null
		};
	},

	methods: {
		logOut() {
			this.$confirm("确认退出登录？", "提示")
				.then((_) => {
					this.confirmLogout();
				})
				.catch((_) => {});
		},
		confirmLogout() {
			loginOut()
				.then((res) => {
					if (res.code === 0) {
						localStorage.removeItem("vuex");
						this.$store.dispatch("clear_user");
						this.$store.dispatch("clear_Menus");
						localStorage.clear();
						this.$router.push({ path: "/login" });
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		toUpdate() {
			const user = this.$store.state.user;
			this.ruleForm.user_id = user.user_id;
			this.dialogVisible = true;
			if (this.$refs["ruleForm"] !== undefined) {
				this.$refs["ruleForm"].resetFields();
			}
		},
		toUpdatePersonal() {
            const user = this.$store.state.user;
            this.userId = user.user_id;
            this.dialogVisibleP=true;
        },
		confirmUpdatePsw(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.updatedPwd();
				} else {
					return false;
				}
			});
		},
		updatedPwd() {
			const data = {
				id: this.$store.state.user.user_id,
				password: this.ruleForm.oldPassword,
				newPassword: this.ruleForm.checkPass,
			};
			updatePassword(data).then((res) => {
				if (res.code === 0) {
					this.$message.success("修改成功");
					// 退出登录
					localStorage.removeItem("vuex");
					this.$store.dispatch("clear_user");
					this.$store.dispatch("clear_Menus");
					localStorage.removeItem("token");
					localStorage.removeItem("username");
					this.$router.push({ path: "/login" });
				} else {
					this.$message.error("修改失败");
				}
			});
		},
		onDarkTheme() {
			this.$addStorageEvent(1, "YH_BF_THEME", "theme-dark");
		},
		onLightTheme() {
			this.$addStorageEvent(1, "YH_BF_THEME", "theme-light");
        },
        // 弹窗关闭时的回调
        onDialogClose () {
        this.dialogVisibleP = false;
        },
        //保存个人信息
        onSave () {
            this.$refs.personalEdit.onSave();
		},
		getAvatar(path) {
            //console.log(path)
			if (
				path &&
				((path.toLowerCase()).indexOf("jpg") !== -1||(path.toLowerCase()).indexOf("png") !== -1||(path.toLowerCase()).indexOf("jpeg") !== -1)
			) {
				return IMG_PREFIX + path;
			}
				return null;
		},
    },
};
</script>

<style lang='scss' scoped>
.header {
	box-sizing: border-box;
	width: 100%;
	height: 50px;
	font-size: 22px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.collapse-btn {
	flex: 2;
	padding: 0 21px;
	cursor: pointer;
	line-height: 50px;
}

.header-user-con {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.user-avator {
	cursor: pointer;
	font-size: 16px;
	text-align: right;
}
.user-avator img {
	vertical-align: middle;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
.el-icon-more {
	margin-left: 20px;
}
.el-dropdown-link {
	color: #fff;
	cursor: pointer;
}
.header_img{
	width: 40px;
	height: 40px;
}
</style>
