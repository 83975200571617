<template>
  <el-container class="homePage">
    <el-aside width="240px">
      <side-bar />
    </el-aside>
    <el-container>
      <el-header>
        <my-header />
      </el-header>

      <el-main id="el-main">
        <my-tags />
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import sideBar from "@/views/layout/SideBar.vue";
import myHeader from "@/views/layout/Header.vue";
import myTags from "@/views/layout/Tags.vue";

export default {
  data() {
    return {
      img: "",
    };
  },
  components: {
    sideBar,
    myHeader,
    myTags,

    // websocketPage
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.homePage {
  @include themify() {
    .el-main {
      overflow: hidden;
      background: themed("n1");
    }
  }
}
</style>
