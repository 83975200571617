<template>
	<el-form
		:model="addForm"
		:rules="rules"
		ref="addForm"
		label-width="82px"
		label-position="left"
		class="demo-form dialog-form"
	>
		<!-- 头像 -->
		<el-form-item label="用户头像：">
			<el-upload
				:action="fileUploadUrl"
				list-type="picture-card"
				:show-file-list="false"
				accept="image/png, image/gif, image/jpg, image/jpeg"
				:headers="fileHeaders"
				:before-remove="handleBeforeRemove"
				:on-success="handleAvatarSuccess"
				ref="upload"
			>
				<img v-if="imageUrl" :src="imageUrl" class="avatar" />
				<i v-else class="el-icon-plus"></i>
			</el-upload>
		</el-form-item>
		<!-- 用户姓名： -->
		<el-form-item label="用户姓名：" prop="realname">
			<el-input v-model.trim="addForm.realname" placeholder="请输入用户姓名"></el-input>
		</el-form-item>
		<!-- 用户名： -->
		<el-form-item label="用户名：" prop="username">
			<el-input v-model.trim="addForm.username" placeholder="请输入用户名"></el-input>
		</el-form-item>
		<!-- 性别 -->
		<el-form-item label="性别：" prop="gender">
			<el-select v-model="addForm.gender" placeholder="请选择性别">
				<el-option label="男" :value="1"></el-option>
				<el-option label="女" :value="0"></el-option>
			</el-select>
		</el-form-item>
		<!-- 联系方式： -->
		<el-form-item label="联系方式：" prop="phone">
			<el-input v-model.trim="addForm.phone" placeholder="请输入手机号码"></el-input>
		</el-form-item>
		<!-- 邮箱： -->
		<el-form-item label="邮箱：" prop="email">
			<el-input v-model="addForm.email"></el-input>
		</el-form-item>
		<!--  用户描述：-->
		<el-form-item label="用户描述：">
			<el-input v-model.trim="addForm.description" type="textarea"></el-input>
		</el-form-item>
	</el-form>
</template>
<script>
import {
	checkPhone,
	checkUsername,
	checkPassword,
	checkName,
	checkEmailNumber,
} from "@/common/utils/index.js";
import {
	getUserPersonal,
	updateUserPersonal,
	IMG_PREFIX,
	UPLOAD_URL,
	HEADERS,
} from "@/api/lib/api.js";
export default {
	data() {
		return {
			isSameUser: false,
			addForm: {
				id: "",
				avatar: "default",
				description: "测试角色",
				email: "qwe@qq.com",
				gender: 0,
				phone: "15011110000",
				realname: "",
				username: "",
			},
			rules: {
				realname: [
					{
						required: true,
						message: "请输入用户姓名",
						trigger: "blur",
					},
					{ validator: checkName, trigger: "blur" },
				],
				phone: [
					{
						required: true,
						message: "请输入手机号码",
						trigger: "blur",
					},
					{ validator: checkPhone, trigger: "blur" },
				],
				username: [
					{
						required: true,
						message: "请输入用户名",
						trigger: "blur",
					},
					{ validator: checkUsername, trigger: "blur" },
				],
				gender: [
					{ required: true, message: "请选择性别", trigger: "blur" },
				],
				email: [
					{
						required: true,
						message: "请输入邮箱地址",
						trigger: "blur",
					},
					{ validator: checkEmailNumber, trigger: "blur" },
				],
			},
			fileUploadUrl: "",
			fileHeaders: "",
			imageUrl: null,
		};
	},
	methods: {
		// 保存信息
		onSave() {
			this.$refs.addForm.validate((valid) => {
				if (valid) {
					let data = {
						id: this.addForm.id,
						realname: this.addForm.realname,
						username: this.addForm.username,
						phone: this.addForm.phone,
						email: this.addForm.email,
						description: this.addForm.description,
						avatar: this.addForm.avatar,
						gender: this.addForm.gender,
					};
					updateUserPersonal(data).then((res) => {
						if (res.code === 0) {
							this.$message.success("保存成功");
						} else {
							this.$message.error("保存失败");
						}
						this.$emit("onDialogClose", res.code === 0);
					});
				}
			});
		},
		// 成功添加照片
		handleAvatarSuccess(res, file) {
			// console.log(res)
			// console.log(file)
			if (res.code === 0) {
				this.imageUrl = IMG_PREFIX + res.data.url;
				this.addForm.avatar = res.data.url;
			}
		},
		// 移除照片确认
		handleBeforeRemove(file, fileList) {
			// getProdsById({ fileName: file.response ? file.response.data : file.url }).then(res => {
			//   if (res.code === 1000) {
			//     this.addForm.photos.forEach((item, index) => {
			//       if (file.response) {
			//         if (item === file.response.data) {
			//           this.addForm.photos.splice(index, 1)
			//         }
			//       } else {
			//         if (item.url === file.url) {
			//           this.addForm.photos.splice(index, 1)
			//         }
			//       }
			//     })
			//   }
			// })
		},
	},
	created() {
		this.fileHeaders = HEADERS;
		this.fileUploadUrl = UPLOAD_URL;
		getUserPersonal().then((res) => {
			if (res.code === 0) {
				this.addForm = { ...res.data };
				if (res.data.avatar) {
					this.imageUrl = IMG_PREFIX + res.data.avatar;
				}
			}
		});
	},
	mounted() {},
};
</script>